import React from 'react';
import './BeeAnimated.css';

import BeeBody from './Images/bee-parts/bee_body.svg';
import BeeWingLeft from './Images/bee-parts/bee_wing-1.svg'
import BeeWingRight from './Images/bee-parts/bee_wing-2.svg'

const BeeAnimated =(props)=>{
    let beeClass = props.beeClass;
    return(
        <div className = { `bee-animated ${beeClass}` }>
            <img className="bee-body" src={ BeeBody } alt="Bee body"/>
            <img className="bee-left-wing" src={ BeeWingLeft } alt="Bee left wing"/>
            <img className="bee-right-wing" src={ BeeWingRight } alt="Bee right wing" />
        </div>
    )
}

export default BeeAnimated;
