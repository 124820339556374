
import React from 'react';
import './Slider.css';

import Bee from "../Bee";
import CtaButton from "./CtaButton";
import SelectionButton from "./SelectionButton";
import ProgressArrow from "./ProgressArrow";
import {useDispatch, useSelector} from "react-redux";
import {incrementPhase, activateSlideUp, increment, nextQuestion} from "../../redux/slider";

const Slider =({props})=>{

    const dispatch = useDispatch();
    const questionState = useSelector((state) => state.slider.value);
    const progressionWarning = useSelector((state) => state.slider.progressionWarning);
    const slideUp = useSelector((state) => state.slider.slideUp);
    const questionsArrows = [];

    for(let i = 1; i <=4; i++) {
        let ProgressArrowComponent = i === questionState ? <ProgressArrow key={ `progress-arrow-${i} `} selected="selected" /> : <ProgressArrow key={ `progress-arrow-${i} `}/>;
        questionsArrows.push(ProgressArrowComponent);
    }

    let progressionInfoText;
    let sliderBgColor;
    let selectionButtons = [];
    let showNextCta;
    let showBackCta;
    let multipleChoice;

    switch (questionState) {

        case 1:
            progressionInfoText = "Pick 1 to 3 categories";
            sliderBgColor = "red";
            showNextCta = true;
            showBackCta = false;
            multipleChoice = true;

            selectionButtons.push("History & Culture");
            selectionButtons.push("Gastronomy");
            selectionButtons.push("Lifestyle");


            break;

        case 2:
            sliderBgColor = "blue";
            progressionInfoText = "Pick 1 option";
            showNextCta = true;
            showBackCta = true;
            multipleChoice = false;

            selectionButtons.push("With");
            selectionButtons.push("Without");
            break;

        case 3:
            progressionInfoText = "Pick 1 option";
            sliderBgColor = "red";
            showNextCta = true;
            showBackCta = true;
            multipleChoice = false;

            selectionButtons.push("With");
            selectionButtons.push("Without");
            break;
        case 4:
            progressionInfoText = "Pick 1 to 3 categories";
            sliderBgColor = "blue";
            showNextCta = true;
            showBackCta = true;
            multipleChoice = true;


            selectionButtons.push("Morning");
            selectionButtons.push("Afternoon");
            selectionButtons.push("Evening");
            break;
        default:
            progressionInfoText = "";
    }

    selectionButtons.forEach(createOptionsListComponents);

    let CtaComponent = showNextCta ? <CtaButton key='cta-next' action="next" class="next-button" text="Next Step" showing_back_button={showBackCta} color={sliderBgColor}/> : '';
    let backButton = showBackCta ? <CtaButton key='cta-back' action="back" class="back-button" text="Back" color={sliderBgColor}/> : '';



    function createOptionsListComponents(item, index, arr) {
        let component = <SelectionButton key={ `selection-button-${index}` } optionIndex={ index } text={ item } color={ sliderBgColor } isNextStep={ !showNextCta } multipleChoice={ multipleChoice }/>;
        arr[index] = component;
    }

    let sliderFrameClass = questionState === 1 ? 'slider-frame': 'slider-frame ' + sliderBgColor;
    sliderFrameClass = questionState >= 2 ? sliderFrameClass + ' active' : sliderFrameClass;

    sliderFrameClass = ( slideUp === true && questionState === 1 ) ? sliderFrameClass + ' slide-up' : sliderFrameClass;

    let slideUpActiveClass = slideUp === true ? 'active' : '';
    let progressionTextActiveClass = progressionWarning === true ? '' : slideUpActiveClass;
    let progressionInfoTextActive =progressionWarning ? 'warning' : '';
    let progressionInfoTextClass = "progression-info-text " + progressionInfoTextActive;

    return(
        <div onClick={() => dispatch(activateSlideUp())} className={sliderFrameClass} >
            <Bee/>
            <div className={`slider-content-wrapper ${slideUpActiveClass}`}>
                <div className={`progression-container ${slideUpActiveClass}`}>
                    { questionsArrows }
                </div>

                <div className={` ${progressionInfoTextClass} ${progressionTextActiveClass}`}>{ progressionInfoText }</div>

                <div className="selection-block">
                    { selectionButtons }

                        { backButton }
                        { CtaComponent }
                </div>


            </div>
        </div>
    )
}

export default Slider;
