import React from "react";
import './TimeMarker.css'

const TimeMarker =(props)=>{
    const time = props.time;
    const expanded = props.expanded;

    return(
        <div className={`time-marker ${expanded ? 'expanded' : ''}`}>
            <div className="time-marker-text">{time}</div>
            <svg width="114" height="100" viewBox="0 0 114 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.809601 7.84425L25.2499 47.1843C25.791 48.034 26.0335 49.0219 26.0335 50.0099C26.0335 50.9978 25.791 51.966 25.2499 52.8354L0.809604 92.1557C-1.76502 96.2853 2.26483 101.482 6.59319 99.6048L111.015 54.7322C113.011 53.8826 114 51.9265 114 50.0099C114 48.0735 113.03 46.1371 111.015 45.2875L6.59319 0.395134C2.24617 -1.48196 -1.76503 3.71464 0.809601 7.86401" fill="#E22136"/>
            </svg>

        </div>
    )

}
export default TimeMarker;
