import React, {useState}  from 'react';
import './ResultCard.css';

import {useDispatch, useSelector} from "react-redux";
import {incrementPhase, toggleReadMoreActive} from "../redux/slider";
import TimeMarker from "./TimeMarker";

import close_readmore from "./Images/UI/close-x.png";
import ReadMoreButton from "./UI/ReadMoreButton"; // Currently needed for CSS

let morning_activity_image;
let morning_activity_image_avif;
let morning_activity_image_webp;
let morning_activity_image_jpeg_xl;
let morning_activity_image_jpeg_2000;

const ResultCard =(props) => {
    const dispatch = useDispatch();
    const time = props.time;
    const activity = props.activity;
    const maltaLink = activity.maltaLink;

    const [readmoreclicked, setReadmoreclicked] = useState(false)
    const readMoreActive = useSelector((state) => state.slider.readMoreActive);

    const getBaseName = (name) => name.split('.').slice(0, -1).join('.');

    try {
        const imageName = activity["img"];
        const imageBaseName = getBaseName(imageName);

        morning_activity_image = require(`./Images/activities/${imageName}`).default;
        morning_activity_image_avif = require(`./Images/activities/${imageBaseName}.avif`).default;
        morning_activity_image_webp = require(`./Images/activities/${imageBaseName}.webp`).default;
        morning_activity_image_jpeg_xl = require(`./Images/activities/${imageBaseName}.jxl`).default;
        morning_activity_image_jpeg_2000 = require(`./Images/activities/${imageBaseName}.jp2`).default;
    } catch (err) {
        const imageName = 'default.png';
        const imageBaseName = 'default';

        morning_activity_image = require(`./Images/activities/${imageName}`).default;
        morning_activity_image_avif = require(`./Images/activities/${imageBaseName}.avif`).default;
        morning_activity_image_webp = require(`./Images/activities/${imageBaseName}.webp`).default;
        morning_activity_image_jpeg_xl = require(`./Images/activities/${imageBaseName}.jxl`).default;
        morning_activity_image_jpeg_2000 = require(`./Images/activities/${imageBaseName}.jp2`).default;
    }


    const clickHandler = () => {
        setReadmoreclicked(!readmoreclicked);
        dispatch(toggleReadMoreActive());
    }
    const closeClickHandler = (maltaLink) => {
        dispatch(toggleReadMoreActive());
        setReadmoreclicked(false);
        window.open(maltaLink, '_blank')
    }

    const readMoreButton =  <div className={`read-more-button ${readmoreclicked ? 'expanded' : ''} `} onClick={clickHandler}>
        <div className="read-more-text">Read More</div>
        <svg width="165" height="35" viewBox="0 0 165 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11.0946C1 5.94772 5.07833 1.742 10.2197 1.61642C26.3271 1.223 59.1111 0.5 82.5 0.5C105.889 0.5 138.673 1.223 154.78 1.61642C159.922 1.742 164 5.94771 164 11.0946V23.9054C164 29.0523 159.922 33.258 154.78 33.3836C138.673 33.777 105.889 34.5 82.5 34.5C59.1111 34.5 26.3271 33.777 10.2197 33.3836C5.07833 33.258 1 29.0523 1 23.9054V11.0946Z" fill="#01526B" stroke="white"/>
        </svg>
    </div>;


    const moreOptionsButton =   <div
        className={`more-options ${readmoreclicked ? 'expanded' : ''} ${readMoreActive ? 'hidden' : ''} `}
        onClick={() => closeClickHandler(maltaLink)}
    >
        <div className="read-more-text">Explore More</div>
        <svg width="165" height="35" viewBox="0 0 165 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11.0946C1 5.94772 5.07833 1.742 10.2197 1.61642C26.3271 1.223 59.1111 0.5 82.5 0.5C105.889 0.5 138.673 1.223 154.78 1.61642C159.922 1.742 164 5.94771 164 11.0946V23.9054C164 29.0523 159.922 33.258 154.78 33.3836C138.673 33.777 105.889 34.5 82.5 34.5C59.1111 34.5 26.3271 33.777 10.2197 33.3836C5.07833 33.258 1 29.0523 1 23.9054V11.0946Z" fill="#01526B" stroke="white"/>
        </svg>
    </div>;



    return(
        <div className="result-card">
            <div className={`image-wrapper ${readmoreclicked ? 'expanded' : ''}`}>
                <button onClick={clickHandler} className={`result-card-close-readmore ${readmoreclicked ? 'expanded' : ''}`}>
                    <img src={ close_readmore } alt="Close the read more section"></img>
                </button>
                <picture>
                    <source srcSet={morning_activity_image_avif} type="image/avif" />
                    <source srcSet={morning_activity_image_jpeg_xl} type="image/jxl" />
                    <source srcSet={morning_activity_image_webp} type="image/webp" />
                    <source srcSet={morning_activity_image_jpeg_2000} type="image/jp2" />
                    <img src={morning_activity_image} alt="" className={`result-image ${readmoreclicked ? 'expanded' : ''}`} />
                </picture>
                <TimeMarker time={time}  expanded={readmoreclicked}/>
            </div>

            <div className={`result-card-title ${readmoreclicked ? 'expanded' : ''}`}>{activity["title"]} </div>
            <div className={`result-card-divider ${readmoreclicked ? 'expanded' : ''}`}></div>
            <div className={`result-card-description ${readmoreclicked ? 'expanded' : ''}`}>{activity["desc"]}</div>

            { readMoreButton }
            { moreOptionsButton }
        </div>
    )
}

export default ResultCard;
