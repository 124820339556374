import React, {useEffect, useRef} from 'react';
import './MapCta.css';
import mapIcon from '../Images/map_icon.png';
import {useDispatch} from "react-redux";
import {setPhase, toggleReadMoreActive} from "../../redux/slider";

const MapCta = () => {
    const dispatch = useDispatch();
    const clickHandler = () => {
        dispatch(setPhase({ phase: 5 }) );
    }

    return(
        <div className="map-cta" onClick={clickHandler} >
            <img className="map-icon" src={ mapIcon } alt="map icon" />
            <span className="map-label">Map of your day</span>
        </div>
    )
}

export default MapCta;
