import React, {useEffect, useRef} from 'react';
import './LandingView.css';
import image from "../Images/more-to-logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {activateSlideUp, nextQuestion} from "../../redux/slider";
import Slider from "../UI/Slider";

const LandingView =()=>{
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const dispatch = useDispatch();
    const slideUp = useSelector((state) => state.slider.slideUp);


    let landingClass = slideUp === true ? 'landing' : '';

    return(
        <div>
            <img src={image} alt="More to explore" className={`hero-logo-moretoexplore initiate ${landingClass}`}/>
            <div className={`hero-title ${landingClass}`}>Experience Malta<br/>your way</div>
            <div className={`hero-subtitle  ${landingClass}`}>Four questions, one minute.<br/>Let's plan your day!</div>
            <Slider/>

        </div>
    )
}

export default LandingView;
