import React  from 'react';
import './CtaButton.css';

import { useDispatch } from "react-redux";
import {increment, decrement} from "../../redux/slider";

const CtaButton =(props)=>{
    const dispatch = useDispatch();
    let button_text = props.text;
    let button_color = props.color;
    let button_class = props.class;
    let showing_back_button = props.showing_back_button;
    button_class += !showing_back_button ? " center_cta" : "";

    let action = props.action;




    const CtaButtonClass = "cta-button "+ button_color + " " + button_class;

    return(
        <div onClick={() =>  { if(action === "next")dispatch(increment()); else { dispatch(decrement()); } } } className={ CtaButtonClass }>
            <div className={`cta-button-text `}> {button_text}</div>
            <svg width="158" height="35" viewBox="0 0 164 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M163.5 11.0946V23.9054C163.5 29.0523 159.422 33.258 154.28 33.3836C138.173 33.777 105.389 34.5 82 34.5C58.6111 34.5 25.8271 33.777 9.71975 33.3836C4.57833 33.258 0.5 29.0523 0.5 23.9054V11.0946C0.5 5.94772 4.57833 1.742 9.71974 1.61642C25.8271 1.223 58.6111 0.5 82 0.5C105.389 0.5 138.173 1.223 154.28 1.61642C159.422 1.742 163.5 5.94771 163.5 11.0946Z" fill="#A71928" stroke="white"/>
            </svg>
        </div>
    )
}

export default CtaButton;
