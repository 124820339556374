import React from "react";
import "./Bee.css";

const Bee =(props)=>{
    let additional_class =props.classname;
    let class_string = "bee "+additional_class;

    return (
        <div>
            <div className={ class_string }>
                <svg width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2_69)">
                        <path d="M39.608 36.1994C39.5465 35.9126 39.4763 35.6282 39.3982 35.3466C38.0819 30.6186 34.3994 26.658 29.4599 24.399C29.237 24.2971 29.0117 24.1987 28.7836 24.1037C26.1156 22.9927 23.1118 22.368 19.9346 22.368C18.8967 22.368 17.8781 22.4352 16.8875 22.5615C21.0695 23.0661 24.3437 24.3034 26.8565 26.0755C27.0156 26.1881 27.1719 26.3025 27.3253 26.4191C30.6696 28.9648 32.5704 32.5107 33.4071 36.5356C33.4538 36.7603 33.4972 36.9867 33.5371 37.2148C33.6563 37.8907 33.7475 38.579 33.8114 39.2776C34.3703 45.3727 31.4777 51.1082 26.532 55.0961C34.3304 52.8034 39.9251 46.5542 39.9251 39.2025C39.9251 38.1774 39.8162 37.1739 39.6075 36.1994H39.608Z" fill="#00252D"/>
                        <path d="M22.9328 38.8993C22.9345 38.9129 22.9356 38.9266 22.9373 38.9402C22.9385 38.9476 22.9391 38.9556 22.9402 38.963C22.9659 39.1865 22.9898 39.4095 23.0103 39.632C23.0543 40.1133 23.0765 40.5928 23.0782 41.069C23.0788 41.2573 23.0759 41.445 23.0702 41.6328C23.0702 41.6384 23.0702 41.6441 23.0697 41.6498C23.0639 41.8307 23.0548 42.0105 23.0434 42.1903C23.0366 42.2978 23.028 42.4047 23.0189 42.5117C22.9795 42.9736 22.9208 43.4316 22.8432 43.8855C22.7959 44.1626 22.7417 44.4379 22.6807 44.7121C22.6396 44.8947 22.5963 45.0762 22.5495 45.2577C22.5028 45.4392 22.4531 45.6189 22.4001 45.7981C22.3408 46.0001 22.2769 46.2009 22.2102 46.4012C22.1583 46.5559 22.1047 46.7106 22.0482 46.8642C21.9193 47.2158 21.779 47.564 21.6273 47.9076C21.3245 48.5954 20.9766 49.2666 20.5877 49.9191C20.4901 50.0824 20.3903 50.244 20.2882 50.405C20.1862 50.566 20.0807 50.7253 19.9734 50.8834C19.8662 51.0416 19.7556 51.1986 19.6432 51.3539C19.4744 51.5871 19.2999 51.8175 19.1203 52.0451C18.5807 52.7266 17.9927 53.3808 17.3597 54.002C17.0785 54.2785 16.7883 54.5482 16.4894 54.8116C16.1906 55.075 15.8837 55.3315 15.5684 55.5807C16.9907 55.8532 18.469 55.9994 19.9905 55.9994C22.3009 55.9994 24.52 55.6632 26.5879 55.0545C31.5331 51.066 34.4257 45.3311 33.8673 39.236C33.816 38.6706 33.7459 38.1119 33.658 37.5607L22.7805 37.8104C22.8387 38.1745 22.8894 38.538 22.9328 38.8987V38.8993Z" fill="#F9C931"/>
                        <path d="M16.49 54.8116C16.7888 54.5482 17.0785 54.2785 17.3603 54.002C17.9933 53.3803 18.5813 52.7266 19.1208 52.0451C19.3004 51.8175 19.475 51.5877 19.6438 51.3539C19.7561 51.1986 19.8662 51.0416 19.974 50.8834C20.0818 50.7253 20.1867 50.566 20.2888 50.405C20.3909 50.244 20.4913 50.0824 20.5882 49.9191C20.9772 49.2666 21.3251 48.5954 21.6279 47.9076C21.7796 47.564 21.9199 47.2158 22.0488 46.8642C22.1052 46.7106 22.1588 46.5559 22.2107 46.4012C22.2775 46.2015 22.3413 46.0007 22.4007 45.7981C22.4537 45.6189 22.5033 45.4386 22.5501 45.2577C22.5968 45.0768 22.6408 44.8947 22.6812 44.7121C22.7423 44.4385 22.797 44.1626 22.8438 43.8855C22.9213 43.4316 22.9801 42.9736 23.0194 42.5117C23.0286 42.4047 23.0366 42.2978 23.044 42.1903C23.0559 42.0105 23.0645 41.8302 23.0702 41.6498C23.0702 41.6441 23.0702 41.6384 23.0708 41.6328C23.0765 41.4456 23.0793 41.2573 23.0788 41.069C23.077 40.5928 23.0548 40.1138 23.0109 39.632C22.9544 39.0147 22.8637 38.4083 22.7411 37.8121L22.4292 37.8195C21.7995 37.8343 21.3228 37.4605 21.1152 36.9724C20.9007 36.4872 20.9532 35.8847 21.3952 35.4364L21.8743 34.9501C19.9495 30.197 15.8615 26.3968 10.6517 24.2766C4.31896 27.1023 0 32.7069 0 39.1615C0 47.1817 6.65723 53.8849 15.5689 55.5807C15.8843 55.331 16.1911 55.0744 16.49 54.8116Z" fill="#00252D"/>
                        <path d="M15.5688 55.5807C15.8842 55.331 16.1911 55.0744 16.4899 54.8116C16.1911 55.075 15.8842 55.3315 15.5688 55.5807Z" fill="#00252D"/>
                        <path d="M21.8737 34.949L22.0944 34.7248C21.8828 34.0012 21.637 33.2918 21.3519 32.6108C19.5725 28.3601 16.275 24.9132 10.6511 24.2755C15.8603 26.3958 19.9483 30.1959 21.8737 34.949Z" fill="#00252D"/>
                        <path d="M22.2101 46.4006C22.1582 46.5554 22.1046 46.7101 22.0481 46.8637C22.1046 46.7101 22.1582 46.5554 22.2101 46.4006Z" fill="#00252D"/>
                        <path d="M23.0702 41.6498C23.0645 41.8307 23.0554 42.0105 23.0439 42.1903C23.0559 42.0105 23.0645 41.8302 23.0702 41.6498Z" fill="#00252D"/>
                        <path d="M23.0194 42.5117C22.9801 42.9737 22.9213 43.4316 22.8438 43.8856C22.9213 43.4316 22.9801 42.9737 23.0194 42.5117Z" fill="#00252D"/>
                        <path d="M22.6812 44.7122C22.6402 44.8948 22.5968 45.0762 22.55 45.2577C22.5968 45.0768 22.6407 44.8948 22.6812 44.7122Z" fill="#00252D"/>
                        <path d="M23.0788 41.069C23.0794 41.2573 23.0765 41.4451 23.0708 41.6328C23.0765 41.4456 23.0794 41.2573 23.0788 41.069Z" fill="#00252D"/>
                        <path d="M17.3598 54.0021C17.0786 54.2786 16.7883 54.5482 16.4895 54.8116C16.7883 54.5482 17.0781 54.2786 17.3598 54.0021Z" fill="#00252D"/>
                        <path d="M20.5882 49.9192C20.4907 50.0825 20.3909 50.244 20.2888 50.405C20.3915 50.2446 20.4913 50.0825 20.5882 49.9192Z" fill="#00252D"/>
                        <path d="M23.0102 39.632C23.0541 40.1133 23.0764 40.5929 23.0781 41.069C23.0764 40.5929 23.0541 40.1139 23.0102 39.632C22.9897 39.4096 22.9663 39.1866 22.9401 38.963C22.9389 38.9556 22.9384 38.9477 22.9372 38.9403C22.9355 38.9266 22.9344 38.913 22.9327 38.8993C22.8899 38.5386 22.8386 38.1751 22.7804 37.811L22.7405 37.8122C22.8631 38.4084 22.9538 39.0148 23.0102 39.632Z" fill="#00252D"/>
                        <path d="M57.1452 34.4057L45.9437 27.0807C45.7002 26.9214 45.5171 26.6961 45.4088 26.439C45.2953 26.1841 45.2554 25.8968 45.3061 25.6107L47.6159 12.4495C47.8594 11.0626 46.1622 10.1922 45.1727 11.1968L29.1817 27.4305L22.0945 34.6258C22.3996 35.6709 22.6334 36.7449 22.8034 37.8116L34.0334 37.5539L56.3895 37.0407C57.801 37.0083 58.3257 35.1782 57.1452 34.4062V34.4057Z" fill="#D6D5DF"/>
                        <path d="M21.3947 35.4359C20.9533 35.8842 20.9003 36.4872 21.1147 36.9719C21.3223 37.46 21.7991 37.8344 22.4287 37.819L22.7407 37.8116C22.5376 36.8252 22.2462 35.8689 21.8738 34.949L21.3947 35.4354V35.4359Z" fill="#00252D"/>
                        <path d="M22.7405 37.8121L22.7804 37.811C22.6156 36.7779 22.3892 35.7374 22.0938 34.7253L21.873 34.9495C22.246 35.8699 22.5375 36.8257 22.7399 37.8121H22.7405Z" fill="#00252D"/>
                        <path d="M57.375 35.7312L46.3246 28.1172C46.0839 27.9516 45.906 27.7212 45.8039 27.4613C45.6961 27.203 45.6636 26.9146 45.7212 26.6284L48.3572 13.4917C48.6349 12.1076 46.9554 11.1934 45.9391 12.177L29.5153 28.0614L22.2358 35.102C22.5164 36.1567 22.7246 37.2393 22.8689 38.3122L34.1319 38.3259L56.5543 38.3532C57.9704 38.3549 58.5407 36.5333 57.3761 35.7306L57.375 35.7312Z" fill="white"/>
                        <path d="M10.8901 37.5635C10.4036 37.5635 10.0095 37.9566 10.0095 38.4419C10.0095 38.9272 10.4036 39.3203 10.8901 39.3203C11.3766 39.3203 11.7706 38.9272 11.7706 38.4419C11.7706 37.9566 11.3766 37.5635 10.8901 37.5635Z" fill="white"/>
                        <path d="M4.81117 37.1978C4.81117 36.7125 4.41708 36.3194 3.93061 36.3194C3.44413 36.3194 3.05005 36.7125 3.05005 37.1978C3.05005 37.683 3.44413 38.0761 3.93061 38.0761C4.41708 38.0761 4.81117 37.683 4.81117 37.1978Z" fill="white"/>
                        <path d="M10.6511 24.276C10.8587 24.2994 11.0623 24.3272 11.2636 24.3585L11.21 24.0371C11.0224 24.1145 10.8353 24.1936 10.6511 24.276Z" fill="#F9C931"/>
                        <path d="M16.9438 22.5211C16.8377 22.5347 16.7322 22.5495 16.6267 22.5649L14.6848 25.3547C17.9322 26.8179 20.0492 29.499 21.3517 32.6114C21.6369 33.2923 21.8827 34.0017 22.0943 34.7254L28.9597 27.7559C26.2724 25.0645 22.372 23.1758 16.9444 22.5211H16.9438Z" fill="#F9C931"/>
                        <path d="M28.2069 5.92778L16.6267 22.5643C14.7145 22.8373 12.8975 23.3408 11.2105 24.0366L7.42649 1.26348C7.25197 0.215026 8.53859 -0.43009 9.27772 0.334492L16.2948 7.58893C16.4477 7.74651 16.641 7.85004 16.8469 7.89442C17.0522 7.94277 17.2712 7.9314 17.4771 7.85403L26.9328 4.29735C27.9291 3.92246 28.8148 5.05511 28.2069 5.92778Z" fill="white"/>
                        <path d="M11.6155 26.4765C11.6959 26.9595 12.0518 27.2696 12.4567 27.3572C12.8599 27.4505 13.315 27.323 13.5944 26.9208L14.6849 25.3547C13.6606 24.8933 12.5234 24.5531 11.2642 24.3586L11.616 26.4771L11.6155 26.4765Z" fill="#00252D"/>
                        <path d="M16.6269 22.5643L14.685 25.3541C13.6602 24.8927 12.5235 24.5525 11.2637 24.358L11.2107 24.0365C12.8977 23.3408 14.7147 22.8373 16.6269 22.5643Z" fill="#F9C931"/>
                        <path d="M10.6511 24.276C10.8587 24.2994 11.0623 24.3272 11.2636 24.3585L11.21 24.0371C11.0224 24.1145 10.8353 24.1936 10.6511 24.276Z" fill="#F9C931"/>
                        <path d="M16.9438 22.5211C16.8377 22.5347 16.7322 22.5495 16.6267 22.5649L14.6848 25.3547C17.9322 26.8179 20.0492 29.499 21.3517 32.6114C21.6369 33.2923 21.8827 34.0017 22.0943 34.7254L28.9597 27.7559C26.2724 25.0645 22.372 23.1758 16.9444 22.5211H16.9438Z" fill="#F9C931"/>
                        <path d="M28.2069 5.92778L16.6267 22.5643C14.7145 22.8373 12.8975 23.3408 11.2105 24.0366L7.42649 1.26348C7.25197 0.215026 8.53859 -0.43009 9.27772 0.334492L16.2948 7.58893C16.4477 7.74651 16.641 7.85004 16.8469 7.89442C17.0522 7.94277 17.2712 7.9314 17.4771 7.85403L26.9328 4.29735C27.9291 3.92246 28.8148 5.05511 28.2069 5.92778Z" fill="white"/>
                        <path d="M11.6155 26.4765C11.6959 26.9595 12.0518 27.2696 12.4567 27.3572C12.8599 27.4505 13.315 27.323 13.5944 26.9208L14.6849 25.3547C13.6606 24.8933 12.5234 24.5531 11.2642 24.3586L11.616 26.4771L11.6155 26.4765Z" fill="#00252D"/>
                        <path d="M16.6269 22.5643L14.685 25.3541C13.6602 24.8927 12.5235 24.5525 11.2637 24.358L11.2107 24.0365C12.8977 23.3408 14.7147 22.8373 16.6269 22.5643Z" fill="#F9C931"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2_69">
                            <rect width="58" height="56" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default Bee;
