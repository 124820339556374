import React from 'react';
import './ProgressArrow.css';

const ProgressArrow =(props)=>{
    let selected = props.selected;
    let progress_class = 'progress-arrow '+selected;

    return(
        <div className= {progress_class}>
            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_19_2556)">
                    <path d="M0.0903036 0.84898L2.85325 5.10678C2.91442 5.19874 2.94183 5.30567 2.94183 5.41259C2.94183 5.51952 2.91442 5.62431 2.85325 5.7184L0.090304 9.97406C-0.200754 10.421 0.254815 10.9834 0.744131 10.7803L12.5489 5.9237C12.7746 5.83174 12.8863 5.62003 12.8863 5.41259C12.8863 5.20302 12.7767 4.99344 12.5489 4.90148L0.744131 0.0427562C0.252706 -0.160404 -0.200755 0.402028 0.0903036 0.851118" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_19_2556">
                        <rect width="10.8231" height="12.8846" fill="white" transform="translate(0 10.8231) rotate(-90)"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    )
}

export default ProgressArrow;
