import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    slideUp: false,
    value: 1,
    phase: 0,
    selections: {
        1: [],
        2: [],
        3: [],
        4: [],

    },
    progressionWarning: false,
    readMoreActive: false,
    backgroundImage: 'landing-view-background',

    debug:0,
    mapPins: [{
        address: 'ABC',
        lat: 35.93990,
        lng: 14.46326,
    },{
        address: 'BCD',
        lat: 35.89933,
        lng: 14.42147,
    },{
        address: 'CDE',
        lat: 35.89920,
        lng: 14.51490,
    },]

}

export const sliderSlice = createSlice({
    name: 'slider',
    initialState,
    reducers: {
        increment: (state) => {
            let value = state.value;
            switch (value){
                case 1:
                case 2:
                case 3:
                case 4:
                    if(state.selections[state.value].length > 0){
                        state.phase = 1;
                        state.progressionWarning = false;
                        state.debug = 999;
                        sliderSlice.caseReducers.nextQuestion(state);
                    }else{
                        sliderSlice.caseReducers.toggleProgressionWarning(state);
                    }
                    if(state.value > 4) {
                        state.phase = 2;
                    }

                    break;

                default:
                    state.phase = 999;
                    state.value = 999;
            }


        },
        decrement: (state) => {
            let value = state.value;
            switch (value){
                case 1:
                case 2:
                case 3:
                case 4:
                        state.phase = 1;
                        state.progressionWarning = false;
                        state.debug = 999;
                        sliderSlice.caseReducers.prevQuestion(state);
                    break;

                default:
                    state.phase = 999;
                    state.value = 999;
            }


        },
        activateSlideUp: (state) => {
            state.slideUp = true
        },
        incrementValue: (state) => {
            state.value += 1
        },
        incrementPhase: (state) => {
            state.phase += 1
        },
        toggleProgressionWarning: (state) => {
            state.progressionWarning = !state.progressionWarning;
        },
        toggleReadMoreActive: (state) => {
            state.readMoreActive = !state.readMoreActive;
        },
        nextQuestion: (state) => {
            state.debug = state.value;
            state.value +=1;

        },
        prevQuestion: (state) => {
            state.debug = state.value;
            state.value -=1;

        },
        setPhase: (state, action) => {
            state.phase = action.payload.phase;
        },

        setMapPins: (state, action) => {
            state.mapPins = action.payload.mapPins;
        },

        addSelection: (state,action) => {
            let question = state.value;

            const removeAtIndex = (arr, index) => {
                const copy = [...arr];
                copy.splice(index, 1);
                return copy;
            };

            const toggle = (arr, item, getValue = item => item) => {
                const index = arr.findIndex(i => getValue(i) === getValue(item));
                if (index === -1) return [...arr, item];
                return removeAtIndex(arr, index);
            };

            if(!action.payload.multipleChoice){
                state.selections[question] = [];
            }

            state.selections[question] = toggle(state.selections[question], action.payload.optionIndex);
            if(action.payload.isNextStep){
                sliderSlice.caseReducers.nextQuestion(state);
            }

        },

    },
})

// Action creators are generated for each case reducer function
export const { setMapPins, increment, decrement, incrementPhase, addSelection, activateSlideUp, nextQuestion, incrementValue, toggleReadMoreActive, setPhase } = sliderSlice.actions

export default sliderSlice.reducer