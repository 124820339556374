import React  from 'react';
import './LoadingView.scoped.css';
import BeeAnimated from "../BeeAnimated";
import {useDispatch, useSelector} from "react-redux";
import {incrementPhase, setMapPins} from "../../redux/slider";

import LoaderBackground from "../Images/UI/loading.jpg";
import LoaderBackgroundWebp from "../Images/UI/loading.webp";
import LoaderBackgroundAvif from "../Images/UI/loading.avif";
import LoaderBackgroundJpegXl from "../Images/UI/loading.jxl";
import LoaderBackgroundJpeg2000 from "../Images/UI/loading.jp2";

const LoadingView =()=>{
    const delayInMilliseconds = 4700;

    const dispatch = useDispatch();
    
    useSelector((state) => state.slider.value);
    //dispatch(setMapPins({mapPins: mapPins}));
    

    setTimeout(function() {
       dispatch(incrementPhase());
    }, delayInMilliseconds);

    return(
        <div className="loading-page">
            <picture>
                <source srcSet={LoaderBackgroundAvif} type="image/avif" />
                <source srcSet={LoaderBackgroundJpegXl} type="image/jxl" />
                <source srcSet={LoaderBackgroundWebp} type="image/webp" />
                <source srcSet={LoaderBackgroundJpeg2000} type="image/jp2" />
                <img src={LoaderBackground} alt="" className="loading-background-image" />
            </picture>
            <BeeAnimated beeClass="loading"/>
            <div className="loader-text">Almost done...</div>
        </div>
    )
}
export default LoadingView;