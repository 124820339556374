import React from 'react';
import './SelectionButton.css';
import {useDispatch, useSelector} from "react-redux";
import {addSelection} from "../../redux/slider";

const SelectionButton = (props)=> {
    const dispatch = useDispatch();
    const questionState = useSelector((state) => state.slider.value);
    const questionSelections = useSelector((state) => state.slider.selections[questionState]);

    let button_text = props.text;
    let button_color = props.color;

    let isNextStep = props.isNextStep;
    let optionIndex = props.optionIndex;

    let multipleChoice = props.multipleChoice;

    let button_selected = questionSelections.indexOf(optionIndex) !== -1 ? 'selected' : '';
    let selectionButtonClass = "selection-button "+ button_color + " " +button_selected;

    return(
        <div onClick={() => dispatch(addSelection({isNextStep: isNextStep, optionIndex: optionIndex, multipleChoice: multipleChoice} ))} className={ selectionButtonClass }>
            <div className="selection-button-text"> {button_text}</div>

            <svg width="325" height="55" viewBox="0 0 325 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 21.7149C0 10.8383 8.66015 1.95922 19.5347 1.74886C51.559 1.12937 116.28 0 162.5 0C208.72 0 273.441 1.12937 305.465 1.74886C316.34 1.95922 325 10.8383 325 21.715V33.2851C325 44.1617 316.34 53.0408 305.465 53.2511C273.441 53.8706 208.72 55 162.5 55C116.28 55 51.559 53.8706 19.5347 53.2511C8.66015 53.0408 0 44.1616 0 33.285V21.7149Z" fill="#C91D2F"/>
            </svg>

        </div>
    )
}

export default SelectionButton;
