import React, {useEffect, useRef, useState} from "react";
import parse from "html-react-parser";
import Slider from "../UI/Slider";
import LoadingView from "./LoadingView";

import "./QuestionnaireView.css";
import { useDispatch, useSelector } from "react-redux";
// Despite not looking included, all of the imports are useful: activeSlideUp, increment, incrementPhase, incrementValue, nextQuestion
import {activateSlideUp, increment, incrementPhase, incrementValue, nextQuestion} from "../../redux/slider";

import ResultView from "./ResultView";
import MapView from "./MapView";
import LandingView from "../Views/LandingView"; //Move markup to this component and load the component (vs having the markup loose in current component)

import LandingImage from "../Images/landing_bg.jpg";
import LandingImageWebp from "../Images/landing_bg.webp";
import LandingImageAvif from "../Images/landing_bg.avif";
import LandingImageJpegXl from "../Images/landing_bg.jxl";
import LandingImageJpeg2000 from "../Images/landing_bg.jp2";

import LoaderBackground from "../Images/UI/loading.jpg";
import LoaderBackgroundWebp from "../Images/UI/loading.webp";
import LoaderBackgroundAvif from "../Images/UI/loading.avif";
import LoaderBackgroundJpegXl from "../Images/UI/loading.jxl";
import LoaderBackgroundJpeg2000 from "../Images/UI/loading.jp2";

import QuestionImage_1 from "../Images/questions/q1.jpg";
import QuestionImage_1_webp from "../Images/questions/q1.webp";
import QuestionImage_1_avif from "../Images/questions/q1.avif";
import QuestionImage_1_jpeg_xl from "../Images/questions/q1.jxl";
import QuestionImage_1_jpeg_2000 from "../Images/questions/q1.jp2";

import QuestionImage_2 from "../Images/questions/q2.jpg";
import QuestionImage_2_webp from "../Images/questions/q2.webp";
import QuestionImage_2_avif from "../Images/questions/q2.avif";
import QuestionImage_2_jpeg_xl from "../Images/questions/q2.jxl";
import QuestionImage_2_jpeg_2000 from "../Images/questions/q2.jp2";

import QuestionImage_3 from "../Images/questions/q3.jpg";
import QuestionImage_3_webp from "../Images/questions/q3.webp";
import QuestionImage_3_avif from "../Images/questions/q3.avif";
import QuestionImage_3_jpeg_xl from "../Images/questions/q3.jxl";
import QuestionImage_3_jpeg_2000 from "../Images/questions/q3.jp2";

import QuestionImage_4 from "../Images/questions/q4.jpg";
import QuestionImage_4_webp from "../Images/questions/q4.webp";
import QuestionImage_4_avif from "../Images/questions/q4.avif";
import QuestionImage_4_jpeg_xl from "../Images/questions/q4.jxl";
import QuestionImage_4_jpeg_2000 from "../Images/questions/q4.jp2";

import MainLogo from "../Images/visitmalta-logo-white-2 1.png"
import Footer from "../Footer";

const QuestionnaireView =() => {
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;

            if (!init) {
                setInit(true);
                preload();
            }
        }, [callback]);


        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const detectImageFormat = async (sample) => new Promise((resolve, reject) => {
        const image = new Image();
        image.onerror = () => resolve(false);
        image.onload = () => resolve(true);
        image.src = sample;
      }).catch(() => false);

    const selectImageFormat = async () => {
        const avifSample = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
        const jpegXlSample = "data:image/jxl;base64,/woIELASCAgQAFwASxLFgkWAHL0xqnCBCV0qDp901Te/5QM=";
        const webpSample = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
        const jpeg2000Sample = "data:image/jp2;base64,/0//UQAyAAAAAAABAAAAAgAAAAAAAAAAAAAABAAAAAQAAAAAAAAAAAAEBwEBBwEBBwEBBwEB/1IADAAAAAEAAAQEAAH/XAAEQED/ZAAlAAFDcmVhdGVkIGJ5IE9wZW5KUEVHIHZlcnNpb24gMi4wLjD/kAAKAAAAAABYAAH/UwAJAQAABAQAAf9dAAUBQED/UwAJAgAABAQAAf9dAAUCQED/UwAJAwAABAQAAf9dAAUDQED/k8+kEAGvz6QQAa/PpBABr994EAk//9k=";
       
        if (await detectImageFormat(avifSample)) return 'avif';
        if (await detectImageFormat(jpegXlSample)) return 'jpegxl';
        if (await detectImageFormat(webpSample)) return 'webp';
        if (await detectImageFormat(jpeg2000Sample)) return 'jpeg2000';
        return 'jpeg';
    };

    const preload = async() => {
        const selectedFormat = await selectImageFormat();

        switch(selectedFormat) {
            case 'avif':
                const preloadedLandingAvif = new Image();
                preloadedLandingAvif.src = LandingImageAvif;
                
                const preloadedLoaderBackgroundAvif = new Image();
                preloadedLoaderBackgroundAvif.src = LoaderBackgroundAvif;
                
                const preloadedLandingQuestionImage1Avif = new Image();
                preloadedLandingQuestionImage1Avif.src = QuestionImage_1_avif;

                const preloadedLandingQuestionImage2Avif = new Image();
                preloadedLandingQuestionImage2Avif.src = QuestionImage_2_avif;
                
                const preloadedLandingQuestionImage3Avif = new Image();
                preloadedLandingQuestionImage3Avif.src = QuestionImage_3_avif;
                
                const preloadedLandingQuestionImage4Avif = new Image();
                preloadedLandingQuestionImage4Avif.src = QuestionImage_4_avif;

                break;
            case 'jpegxl':
                const preloadedLandingJpegXl = new Image();
                preloadedLandingJpegXl.src = LandingImageJpegXl;
                
                const preloadedLoaderBackgroundJpegXl = new Image();
                preloadedLoaderBackgroundJpegXl.src = LoaderBackgroundJpegXl;
                
                const preloadedLandingQuestionImage1JpegXl = new Image();
                preloadedLandingQuestionImage1JpegXl.src = QuestionImage_1_jpeg_xl;

                const preloadedLandingQuestionImage2JpegXl = new Image();
                preloadedLandingQuestionImage2JpegXl.src = QuestionImage_2_jpeg_xl;
                
                const preloadedLandingQuestionImage3JpegXl = new Image();
                preloadedLandingQuestionImage3JpegXl.src = QuestionImage_3_jpeg_xl;
                
                const preloadedLandingQuestionImage4JpegXl = new Image();
                preloadedLandingQuestionImage4JpegXl.src = QuestionImage_4_jpeg_xl;

                break;
            case 'webp':
                const preloadedLandingWebp = new Image();
                preloadedLandingWebp.src = LandingImageWebp;
                
                const preloadedLoaderBackgroundWebp = new Image();
                preloadedLoaderBackgroundWebp.src = LoaderBackgroundWebp;
                
                const preloadedLandingQuestionImage1Webp = new Image();
                preloadedLandingQuestionImage1Webp.src = QuestionImage_1_webp;

                const preloadedLandingQuestionImage2Webp = new Image();
                preloadedLandingQuestionImage2Webp.src = QuestionImage_2_webp;
                
                const preloadedLandingQuestionImage3Webp = new Image();
                preloadedLandingQuestionImage3Webp.src = QuestionImage_3_webp;
                
                const preloadedLandingQuestionImage4Webp = new Image();
                preloadedLandingQuestionImage4Webp.src = QuestionImage_4_webp;

                break;
            case 'jpeg2000':
                const preloadedLandingJpeg2000 = new Image();
                preloadedLandingJpeg2000.src = LandingImageJpeg2000;
                
                const preloadedLoaderBackgroundJpeg2000 = new Image();
                preloadedLoaderBackgroundJpeg2000.src = LoaderBackgroundJpeg2000;
                
                const preloadedLandingQuestionImage1Jpeg2000 = new Image();
                preloadedLandingQuestionImage1Jpeg2000.src = QuestionImage_1_jpeg_2000;

                const preloadedLandingQuestionImage2Jpeg2000 = new Image();
                preloadedLandingQuestionImage2Jpeg2000.src = QuestionImage_2_jpeg_2000;
                
                const preloadedLandingQuestionImage3Jpeg2000 = new Image();
                preloadedLandingQuestionImage3Jpeg2000.src = QuestionImage_3_jpeg_2000;
                
                const preloadedLandingQuestionImage4Jpeg2000 = new Image();
                preloadedLandingQuestionImage4Jpeg2000.src = QuestionImage_4_jpeg_2000;

                break;
            default:
                const preloadedLanding = new Image();
                preloadedLanding.src = LandingImage;
                
                const preloadedLoaderBackground = new Image();
                preloadedLoaderBackground.src = LoaderBackground;
                
                const preloadedLandingQuestionImage1 = new Image();
                preloadedLandingQuestionImage1.src = QuestionImage_1;

                const preloadedLandingQuestionImage2 = new Image();
                preloadedLandingQuestionImage2.src = QuestionImage_2;
                
                const preloadedLandingQuestionImage3 = new Image();
                preloadedLandingQuestionImage3.src = QuestionImage_3;
                
                const preloadedLandingQuestionImage4 = new Image();
                preloadedLandingQuestionImage4.src = QuestionImage_4;
        }
    };
    
    const [ready, setReady] = useState(true)

    useEffect(() => {
        setReady(true);
    }, [])

    const [init, setInit] = useState(false);

    useEffect(() => {
        if (!init) {
            setInit(true);
            preload();
        }
    }, [init]);

    const dispatch = useDispatch();
    const questionState = useSelector((state) => state.slider.value);
    const slideUp = useSelector((state) => state.slider.slideUp);
    let selectionAll = useSelector((state) => state.slider.selections);
    let applicationPhase = useSelector((state) => state.slider.phase);

    useInterval(() => {
        dispatch(activateSlideUp());
    }, slideUp ? null : 3000);

    const location = {
        address: 'Malta',
        lat: 35.94245,
        lng: 14.37835,
    }



    let phaseComponent;
    let questionnaireWrapperClass = "";
    let questionText;
    let questionImage;
    let questionImageWebp;
    let questionImageAvif;
    let questionImageJpegXl;
    let questionImageJpeg2000;
    let questionImageComponent;
    let questionClasses;
    let questionBackgroundClass;
    let nextQuestionImage;
    let nextQuestionBackgroundClass;
    let nextQuestionImageWebp, nextQuestionImageAvif, nextQuestionImageJpegXl, nextQuestionImageJpeg2000;

    switch (questionState) {
        case 1:
            questionText = 'What are you up <br /> for today?';
            questionImage = QuestionImage_1;
            questionImageWebp = QuestionImage_1_webp;
            questionImageAvif = QuestionImage_1_avif;
            questionImageJpegXl = QuestionImage_1_jpeg_xl;
            questionImageJpeg2000 = QuestionImage_1_jpeg_2000;
            questionClasses = "background-cover";
            questionBackgroundClass = "landing-view-background";

            nextQuestionImage = QuestionImage_2;
            nextQuestionImageWebp = QuestionImage_2_webp;
            nextQuestionImageAvif = QuestionImage_2_avif;
            nextQuestionImageJpegXl = QuestionImage_2_jpeg_xl;
            nextQuestionImageJpeg2000 = QuestionImage_2_jpeg_2000;
            nextQuestionBackgroundClass = "question2background";

            break;
        case 2:
            questionText = 'Exploring with <br /> children?';
            questionImage = QuestionImage_2;
            questionImageWebp = QuestionImage_2_webp;
            questionImageAvif = QuestionImage_2_avif;
            questionImageJpegXl = QuestionImage_2_jpeg_xl;
            questionImageJpeg2000 = QuestionImage_2_jpeg_2000;
            questionClasses = "background-cover";
            questionBackgroundClass = "question2background";

            nextQuestionImage = QuestionImage_3;
            nextQuestionImageWebp = QuestionImage_3_webp;
            nextQuestionImageAvif = QuestionImage_3_avif;
            nextQuestionImageJpegXl = QuestionImage_3_jpeg_xl;
            nextQuestionImageJpeg2000 = QuestionImage_3_jpeg_2000;
            nextQuestionBackgroundClass = "question3background";

            break;
        case 3:
            questionText = 'Exploring with a <br /> touch of adrenaline?';
            questionImage = QuestionImage_3;
            questionImageWebp = QuestionImage_3_webp;
            questionImageAvif = QuestionImage_3_avif;
            questionImageJpegXl = QuestionImage_3_jpeg_xl;
            questionImageJpeg2000 = QuestionImage_3_jpeg_2000;
            questionClasses = "background-cover";
            questionBackgroundClass = "question3background";

            nextQuestionImage = QuestionImage_4;
            nextQuestionImageWebp = QuestionImage_4_webp;
            nextQuestionImageAvif = QuestionImage_4_avif;
            nextQuestionImageJpegXl = QuestionImage_4_jpeg_xl;
            nextQuestionImageJpeg2000 = QuestionImage_4_jpeg_2000;
            nextQuestionBackgroundClass = "question4background";


            break;
        case 4:
            questionText = 'At what time are <br /> you exploring?';
            questionImage = QuestionImage_4;
            nextQuestionImage = LoaderBackground;

            questionImageWebp = QuestionImage_4_webp;
            questionImageAvif = QuestionImage_4_avif;
            questionImageJpegXl = QuestionImage_4_jpeg_xl;
            questionImageJpeg2000 = QuestionImage_4_jpeg_2000;


            nextQuestionImageWebp = LoaderBackgroundWebp;
            nextQuestionImageAvif = LoaderBackgroundAvif;
            nextQuestionImageJpegXl = LoaderBackgroundJpegXl;
            nextQuestionImageJpeg2000 = LoaderBackgroundJpeg2000;

            questionClasses = "background-cover";
            questionBackgroundClass = "question4background";
            nextQuestionBackgroundClass = "loading-background-image";

            break;
        default:

            break;
    }

    switch (applicationPhase){
        case 0:
            document.body.classList.add("landing-scroll-fix");
            phaseComponent = <LandingView/>
            questionImageComponent = <picture>
                <source srcSet={LandingImageAvif} type="image/avif" />
                <source srcSet={LandingImageJpegXl} type="image/jxl" />
                <source srcSet={LandingImageWebp} type="image/webp" />
                <source srcSet={LandingImageJpeg2000} type="image/jp2" />
                <img src={LandingImage} alt="" className="background-cover landing-view-background" />
            </picture>
            questionImage = LandingImage;
            questionClasses = "background-cover landing-view-background";
            questionnaireWrapperClass = "no-scroll";
            break;

        case 1:
            phaseComponent = <Slider/>;
            questionImageComponent = <picture>
            <source srcSet={questionImageAvif} type="image/avif" />
            <source srcSet={questionImageJpegXl} type="image/jxl" />
            <source srcSet={questionImageWebp} type="image/webp" />
            <source srcSet={questionImageJpeg2000} type="image/jp2" />
            <img src={questionImage} alt="" className={questionClasses} />
        </picture>
            questionnaireWrapperClass = "no-scroll";

            break;

        case 2:
            questionnaireWrapperClass = "";
            phaseComponent = <LoadingView/>;
            questionnaireWrapperClass = "no-scroll";
            break;

        case 3:
            document.body.classList.remove("landing-scroll-fix");
            questionnaireWrapperClass = "";
            phaseComponent = <ResultView ready={ ready } />;
            questionnaireWrapperClass = "scroll";

            break;

        case 5:
            questionnaireWrapperClass = "";
            phaseComponent = <MapView location={location} zoomLevel={11} />;
            break;

        default:
            break;
    }

    
    document.body.classList.remove("no-scroll");
    document.body.classList.remove("scroll");
    if(questionnaireWrapperClass !== ""){
        document.body.classList.add(questionnaireWrapperClass);
    }


    return(
        <div className={questionnaireWrapperClass}>
            <div>
                <img className="main-logo mobile-hidden" src={MainLogo}  alt="" />
                { applicationPhase < 2 &&

                    <div className={"background-wrapper"}>
                    <picture>
                    <source srcSet={nextQuestionImageAvif} type="image/avif" />
                    <source srcSet={nextQuestionImageJpegXl} type="image/jxl" />
                    <source srcSet={nextQuestionImageWebp} type="image/webp" />
                    <source srcSet={nextQuestionImageJpeg2000} type="image/jp2" />
                    <img src={nextQuestionImage} alt="" className={`${questionClasses} ${nextQuestionBackgroundClass}`} />
                </picture>
                    </div>
                    }

                { applicationPhase < 2 &&
                    <div className={"background-wrapper"}>
                    <picture>
                    <source srcSet={questionImageAvif} type="image/avif" />
                    <source srcSet={questionImageJpegXl} type="image/jxl" />
                    <source srcSet={questionImageWebp} type="image/webp" />
                    <source srcSet={questionImageJpeg2000} type="image/jp2" />

                    <img src={questionImage} alt="" className={`${questionClasses} ${questionBackgroundClass}`} />
                </picture>
                    </div>
                    }

                { applicationPhase < 2 && <div className={ `question-text ${slideUp ? 'active' : ''}` }>{ parse(questionText) }</div> }

                { phaseComponent }
            </div>
           { applicationPhase < 2 && <Footer isMobileHidden={ true } /> }
            <script>
                document.body.requestFullscreen();
            </script>
        </div>
    );

}

export default QuestionnaireView;
