import React from 'react';
import './MapView.css';
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import MapReturnButton from "../UI/MapReturnButton";
import {useDispatch, useSelector} from "react-redux";
import {setPhase} from "../../redux/slider";


const MapView = ({location, zoomLevel}) => {
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.slider.mapPins);

    const clickHandler = () => {
        dispatch(setPhase({phase: 3}));
    }

    return (
        <>
            <button className={"map back"} onClick={clickHandler} />

            <MapContainer className={"MapContainer"} center={[locations[1].lat, locations[1].lng]} zoom={11}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {
                    locations.map((location, index) => (
                        <Marker key={index} position={[location.lat, location.lng]}>
                            <Popup>
                                {location.title}
                            </Popup>
                        </Marker>
                    ))
                }
            </MapContainer>
        </>

    )
}

export default MapView;